const Items = [
  {
    className: "products",
    heading: "GetItNow",
    link: [
      {
        localize: true,
        label: "Docs",
        href: "/download-docs.aspx",
      },
      {
        localize: true,
        label: "DocSpace",
        href: "/download-docspace.aspx",
      },
      {
        localize: true,
        label: "Workspace",
        href: "/download-workspace.aspx",
      },
      {
        localize: true,
        label: "Connectors",
        href: "/all-connectors.aspx",
      },
      {
        localize: true,
        label: "DesktopApps",
        href: "/download-desktop.aspx",
      },
      {
        localize: true,
        label: "MobileApps",
        href: "/download-desktop.aspx#mobile",
      },
    ],
  },
  {
    className: "collaborate",
    heading: "Collaborate",
    link: [
      {
        localize: true,
        label: "ForContributors",
        href: "/contribute.aspx",
      },
      {
        isInternal: true,
        localize: false,
        label: "ForTranslators",
        href: "/docs/contribution/become-translator.aspx",
      },
      {
        localize: true,
        label: "ForInfluencers",
        href: "/influencer-program.aspx",
      },
      {
        localize: true,
        label: "Vacancies",
        href: "/vacancies.aspx",
      },
    ],
  },
  {
    className: "get_news",
    heading: "GetNews",
    link: [
      {
        localize: true,
        label: "Blog",
        href: "/blog/",
      },
    ],
  },
  {
    className: "get_help",
    heading: "GetHelp",
    link: [
      {
        localize: false,
        label: "Forum",
        href: "https://forum.onlyoffice.com/",
      },
      {
        localize: true,
        label: "TrainingCourses",
        href: "/training-courses.aspx",
      },
      {
        localize: true,
        label: "Webinars",
        href: "/webinars.aspx",
      },
      {
        localize: true,
        label: "WhitePapers",
        href: "/whitepapers.aspx",
      },
      {
        localize: true,
        label: "SupportContactForm",
        href: "/support-contact-form.aspx",
      },
      {
        localize: true,
        label: "OrderDemo",
        href: "/demo-order.aspx",
      },
    ],
  },
  {
    className: "contacts",
    heading: "ContactUs",
    link: [
      {
        label: "SalesQuestions",
        mail: "sales@onlyoffice.com",
        href: "mailto:sales@onlyoffice.com",
        isContact: true,
      },
      {
        label: "PartnerInquiries",
        mail: "partners@onlyoffice.com",
        href: "mailto:partners@onlyoffice.com",
        isContact: true,
      },
      {
        label: "PressInquiries",
        mail: "press@onlyoffice.com",
        href: "mailto:press@onlyoffice.com",
        isContact: true,
      },
      {
        localize: true,
        label: "RequestACall",
        href: "/call-back-form.aspx",
      }
    ],
  },
];

export default Items;